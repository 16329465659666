import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// material-ui icons
import GroupIcon from "@material-ui/icons/Group";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import UserService from "services/UserService";
import useAPI from "utils/useAPI";
import Chip from "@material-ui/core/Chip";
import MaterialTable, { MTableToolbar } from "material-table";
import history from "utils/history";

const useStyles = makeStyles((theme) => ({
  ...styles,
  chip: {
    margin: theme.spacing(0.5),
  },
}));

export default function Users() {
  const api = useAPI();
  const classes = useStyles();
  let path = "/users/edit/";
  const tableRef = React.createRef();

  function transformUsers(users) {
    let transformed_users = users;
    transformed_users = transformed_users.map((user) => {
      var vehicleList = "";
      var vehicleMakes = user.vehicles.map((v) => v.make);
      var uniqueVehicleMakes = vehicleMakes.filter(
        (v, i, a) => a.indexOf(v) === i
      );
      vehicleList = (
        <>
          {uniqueVehicleMakes.map((v) => (
            <Chip
              variant="outlined"
              className={classes.chip}
              key={v}
              label={v}
              m={2}
            />
          ))}
        </>
      );
      return {
        id: user.id,
        name: user.nickname,
        email: user.email,
        role: user.role,
        status: user.status,
        picture: user.picture,
        vehicle: vehicleList,
        is_agree: user.is_agree,
      };
    });
    return transformed_users;
  }

  React.useEffect(() => {
    UserService.init(api);
  }, [api]);

  function handleData(query) {
    return new Promise((resolve, reject) => {
      query.page += 1;
      UserService.getUsers(query)
        .then((res) => {
          resolve({
            data: transformUsers(res.results),
            page: res.page - 1,
            totalCount: res.total,
          });
        })
        .catch(reject);
    });
  }

  return (
    <GridContainer>
      <div className={classes.cardContentLeft}></div>
      <GridItem xs={12}>
        <Card>
          <MaterialTable
            title="Users"
            tableRef={tableRef}
            columns={[
              { title: "ID", field: "id", filtering: false },
              { title: "Name", field: "name", filtering: false },
              { title: "Email", field: "email", filtering: false },
              {
                title: "Status",
                field: "status",
                lookup: {
                  active: "Active",
                  waiting: "Waiting",
                  suspend: "Suspend",
                },
              },
              {
                title: "Vehicles",
                field: "vehicle",
                filtering: false,
                sorting: false,
              },
              {
                title: "Role",
                field: "role",
                lookup: {
                  admin: "Admin",
                  customer: "Customer",
                  "carousel-admin": "Park Admin",
                },
              },
              {
                title: "Terms",
                field: "is_agree",
                lookup: { 0: "Not Accepted", 1: "Accepted" },
              },
            ]}
            data={handleData}
            actions={[
              (rowData) => ({
                icon: "edit",
                iconProps: { fontSize: "small" },
                tooltip: "Update User",
                onClick: () => {
                  history.push(path + rowData.id);
                },
              }),
            ]}
            components={{
              Toolbar: (props) => (
                <div>
                  <CardHeader color="primary" icon>
                    <CardIcon color="rose">
                      <GroupIcon />
                    </CardIcon>
                  </CardHeader>
                  <MTableToolbar {...props} />
                </div>
              ),
            }}
            options={{
              actionsColumnIndex: -1,
              debounceInterval: 500,
              filtering: true,
              sorting: true,
              pageSize: 10,
            }}
          />
        </Card>
      </GridItem>
    </GridContainer>
  );
}
