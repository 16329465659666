import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import SubscriptionBookingService from "services/SubscriptionBookingService";
import useAPI from "utils/useAPI";
import buttons from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.js";
import { useHistory } from "react-router-dom";
import tableStyles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import MaterialTable, { MTableToolbar } from "material-table";
import { useParams } from "react-router-dom";
import moment from "moment";
import BookIcon from "@material-ui/icons/Book";
const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
    buttons,
  },
  ...tableStyles,
};

const useStyles = makeStyles(styles);

export default function ReactTables() {
  const api = useAPI();
  const history = useHistory();
  const tableRef = React.createRef();
  let params = useParams();
  const [subscriptionBooking, setSubscriptionBooking] = React.useState(null);
  const fetchSubscriptionHistory = async () => {
    SubscriptionBookingService.getSubscriptionBookingDetail(params.id).then(
      (res) => {
        console.log(res);
        setSubscriptionBooking(res);
      }
    );
  };
  React.useEffect(() => {
    SubscriptionBookingService.init(api);
    fetchSubscriptionHistory();
  }, [api]);

  const classes = useStyles();

  function transform(session) {
    let bookingHistory = session;
    bookingHistory = bookingHistory.map((ses) => {
      return {
        id: ses.id,
        start_time:
          (ses.start_time &&
            moment(ses.start_time).format("MM/DD/YYYY hh:mm A")) ||
          "NA",
        end_time:
          (ses.end_time && moment(ses.end_time).format("MM/DD/YYYY hh:mm A")) ||
          "NA",
        duration: ses.duration != null ? ses.duration : "NA",
        status: String(ses.status).toUpperCase() + " ",
      };
    });
    return bookingHistory;
  }

  return (
    <GridContainer>
      <div className={classes.cardContentLeft}></div>
      <GridItem xs={12}>
        <Card>
          <CardBody>
            <h4 className={classes.cardIconTitle}>
              {" "}
              <b>
                User:{" "}
                {subscriptionBooking &&
                  subscriptionBooking.user &&
                  subscriptionBooking.user.name}
              </b>
            </h4>
            <h4 className={classes.cardIconTitle}>
              <b>
                Vehicle:{" "}
                {subscriptionBooking && subscriptionBooking.vehicle_name}
              </b>
            </h4>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12}>
        <Card>
          <MaterialTable
            title="Booking History"
            tableRef={tableRef}
            columns={[
              { title: "Start Time", field: "start_time" },
              { title: "End Time", field: "end_time" },
              { title: "Duration (Minutes)", field: "duration" },
              { title: "Status", field: "status" },
            ]}
            data={(query) =>
              new Promise((resolve, reject) => {
                SubscriptionBookingService.getBookingHistory(
                  params.id,
                  query.pageSize,
                  query.page + 1,
                  query.search,
                  query.orderBy ? query.orderBy.field : null,
                  query.orderDirection
                )
                  .then((res) => {
                    console.log("Here", res);
                    resolve({
                      data: transform(res.results),
                      page: res.page - 1,
                      totalCount: res.total,
                    });
                  })
                  .catch(reject);
              })
            }
            components={{
              Toolbar: (props) => (
                <div style={{ alignItems: "center", paddingRight: "0%" }}>
                  <CardHeader color="primary" icon>
                    <CardIcon color="rose">
                      <BookIcon />
                    </CardIcon>
                  </CardHeader>
                  <MTableToolbar {...props} />
                </div>
              ),
            }}
            options={{
              actionsColumnIndex: -1,
              debounceInterval: 500,
              filtering: false,
              sorting: true,
              pageSize: 10,
            }}
          />
        </Card>
      </GridItem>
    </GridContainer>
  );
}
