import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import CreateIcon from "@material-ui/icons/Create";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";

import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import useAPI from "utils/useAPI";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import TicketService from "services/TicketService";
import { FormControl, TextField } from "@material-ui/core";

const useStyles = makeStyles(styles);
const damageList = [
  { id: 1, name: "Roof" },
  { id: 2, name: "Side Mirrors" },
  { id: 3, name: "Doors" },
  { id: 4, name: "Glasses" },
];
const vehicleSizeList = [
  { id: 1, name: "Non Electric SUV" },
  { id: 2, name: "Electric SUV" },
  { id: 3, name: "Non Electric Car" },
  { id: 4, name: "Electric Car" },
];
const vehicleStatusList = [
  { id: 0, name: "unparked" },
  { id: 1, name: "parked" },
  { id: 2, name: "retrieved" },
];
const paymentStatusList = [
  { id: 1, name: "paid" },
  { id: 2, name: "unpaid" },
];
const paymentTypeList = [
  { id: 1, name: "cash" },
  { id: 2, name: "card" },
];
export default function RegularForms() {
  const classes = useStyles();
  const history = useHistory();
  const api = useAPI();
  let params = useParams();

  const [ticket, setTicket] = React.useState({
    phone: "",
    name: "",
    vehicle_type: "",
    color: "",
    plate: "",
    vehicle_exit: "",
    vehicle_damage: "",
    vehicle_status: "",
    payment_status: "",
    payment_type: "",
  });

  const [errors, setErrors] = React.useState({
    phone: "",
    name: "",
    vehicle_type: "",
    color: "",
    plate: "",
    vehicle_exit: "",
    vehicle_damage: "",
    vehicle_status: "",
    payment_status: "",
    payment_type: "",
  });

  const fetchTicket = async () => {
    await TicketService.getTicket(params.id).then((res) => {
      setTicket(res);
    });
  };

  React.useEffect(() => {
    TicketService.init(api);
    fetchTicket();
  }, [api]);

  const handleChange = (prop, value) => {
    setTicket({ ...ticket, [prop]: value });
    if (verifyLength(value, 1)) {
      setErrors({ ...errors, [prop]: "success" });
    } else {
      setErrors({ ...errors, [prop]: "error" });
    }
  };
  const verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };
  const handleSave = async () => {
    console.log(ticket);
    let error = 0;
    let newErrors = errors;
    ["vehicle_type"].forEach((field) => {
      if (ticket[field] === "") {
        newErrors[field] = "error";
        error = 1;
      }
    });
    setErrors({ ...newErrors });

    if (error) return;
    let path = `/admin/ticket`;
    await TicketService.updateTicket(ticket).then(() => {
      history.push(path);
    });
  };
  const cancelRoute = () => {
    window.history.back();
    // let path = `/admin/carousels`;
    // history.push(path);
  };
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={6}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <CreateIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Update Ticket</h4>
          </CardHeader>
          <CardBody>
            <form>
              <FormControl
                variant="outlined"
                fullWidth
                margin="dense"
                className={classes.formControl}
              >
                <InputLabel id="vehicle_damage">Damage</InputLabel>
                <Select
                  labelId="vehicle_damage"
                  id="vehicle_damage"
                  fullWidth
                  value={ticket.vehicle_damage}
                  onChange={(event) => {
                    handleChange("vehicle_damage", event.target.value);
                  }}
                  label="Damage"
                >
                  <MenuItem
                    disabled
                    value=""
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    Choose Damage
                  </MenuItem>

                  {damageList &&
                    damageList.map((item) => (
                      <MenuItem
                        value={`${item.name}`}
                        key={`${item.id}`}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                      >
                        {`${item.name}`}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>

              <TextField
                error={errors.plate === "error"}
                autoFocus
                margin="dense"
                id="exit"
                label="Add Exit"
                variant="outlined"
                type="text"
                fullWidth
                value={ticket.vehicle_exit}
                onChange={(event) => {
                  handleChange("vehicle_exit", event.target.value);
                }}
              />

              <FormControl
                variant="outlined"
                fullWidth
                margin="dense"
                className={classes.formControl}
              >
                <InputLabel id="vehicle_type">Vehicle Type</InputLabel>
                <Select
                  labelId="vehicle_type"
                  id="vehicle_type"
                  fullWidth
                  value={ticket.vehicle_type}
                  onChange={(event) => {
                    handleChange("vehicle_type", event.target.value);
                  }}
                  label="Vehicle Type"
                >
                  <MenuItem
                    disabled
                    value=""
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    Choose Vehicle Type
                  </MenuItem>

                  {vehicleSizeList &&
                    vehicleSizeList.map((item) => (
                      <MenuItem
                        value={`${item.name}`}
                        key={`${item.id}`}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                      >
                        {`${item.name}`}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>

              <FormControl
                variant="outlined"
                fullWidth
                margin="dense"
                className={classes.formControl}
              >
                <InputLabel id="vehicle_status">Vehicle Status</InputLabel>
                <Select
                  labelId="vehicle_status"
                  id="vehicle_status"
                  fullWidth
                  value={ticket.vehicle_status}
                  onChange={(event) => {
                    handleChange("vehicle_status", event.target.value);
                  }}
                  label="Vehicle Status"
                >
                  <MenuItem
                    disabled
                    value=""
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    Choose Vehicle Status
                  </MenuItem>

                  {vehicleStatusList &&
                    vehicleStatusList.map((item) => (
                      <MenuItem
                        value={`${item.name}`}
                        key={`${item.id}`}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                      >
                        {capitalizeFirstLetter(`${item.name}`)}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>

              <FormControl
                variant="outlined"
                fullWidth
                margin="dense"
                className={classes.formControl}
              >
                <InputLabel id="payment_status">Payment Status</InputLabel>
                <Select
                  labelId="payment_status"
                  id="payment_status"
                  fullWidth
                  value={ticket.payment_status}
                  onChange={(event) => {
                    handleChange("payment_status", event.target.value);
                  }}
                  label="Payment Status"
                >
                  <MenuItem
                    disabled
                    value=""
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    Choose Payment Status
                  </MenuItem>

                  {paymentStatusList &&
                    paymentStatusList.map((item) => (
                      <MenuItem
                        value={`${item.name}`}
                        key={`${item.id}`}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                      >
                        {capitalizeFirstLetter(`${item.name}`)}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl
                variant="outlined"
                fullWidth
                margin="dense"
                className={classes.formControl}
              >
                <InputLabel id="payment_type">Payment Type</InputLabel>
                <Select
                  labelId="payment_type"
                  id="payment_type"
                  fullWidth
                  value={ticket.payment_type}
                  onChange={(event) => {
                    handleChange("payment_type", event.target.value);
                  }}
                  label="Payment Type"
                >
                  <MenuItem
                    disabled
                    value=""
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    Choose Payment Type
                  </MenuItem>

                  {paymentTypeList &&
                    paymentTypeList.map((item) => (
                      <MenuItem
                        value={`${item.name}`}
                        key={`${item.id}`}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                      >
                        {capitalizeFirstLetter(`${item.name}`)}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>

              <Button color="success" onClick={handleSave}>
                Update
              </Button>
              <Button color="danger" onClick={cancelRoute}>
                Cancel
              </Button>
            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
