import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import LocationService from "services/LocationService";
import useAPI from "utils/useAPI";
import buttons from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.js";
import { useHistory } from "react-router-dom";
import tableStyles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import MaterialTable, { MTableToolbar } from "material-table";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
    buttons,
  },
  ...tableStyles,
};

const useStyles = makeStyles(styles);

export default function ReactTables() {
  const api = useAPI();
  const history = useHistory();
  let path = "/location/edit/";
  // let path = "/vehicles/edit/";
  const tableRef = React.createRef();

  React.useEffect(() => {
    LocationService.init(api);
  }, [api]);

  const classes = useStyles();
  return (
    <GridContainer>
      <div className={classes.cardContentLeft}></div>
      <GridItem xs={12}>
        <Card>
          <MaterialTable
            title="Location"
            tableRef={tableRef}
            columns={[
              { title: "Name", field: "name" },
              { title: "Latitude", field: "latitude" },
              { title: "Longitude", field: "longitude" },
              { title: "Zipcode", field: "zipcode" },
              { title: "Available Space", field: "available_space" },
            ]}
            data={(query) =>
              new Promise((resolve, reject) => {
                LocationService.getLocationList(
                  query.pageSize,
                  query.page + 1,
                  query.search,
                  query.orderBy ? query.orderBy.field : null,
                  query.orderDirection
                )
                  .then((res) => {
                    console.log(res.results);
                    resolve({
                      data: res.results,
                      page: res.page - 1,
                      totalCount: res.total,
                    });
                  })
                  .catch(reject);
              })
            }
            actions={[
              {
                icon: "add",
                tooltip: "Add Location",
                position: "toolbar",
                onClick: () => {
                  history.push("/location/add");
                },
              },
              (rowData) => ({
                icon: "edit",
                iconProps: { fontSize: "small" },
                tooltip: "Update Location",
                onClick: () => {
                  history.push(path + rowData.id);
                },
              }),
            ]}
            components={{
              Toolbar: (props) => (
                <div style={{ alignItems: "center", paddingRight: "0%" }}>
                  <CardHeader color="primary" icon>
                    <CardIcon color="rose">
                      <LocationOnIcon />
                    </CardIcon>
                  </CardHeader>
                  <MTableToolbar {...props} />
                </div>
              ),
            }}
            options={{
              actionsColumnIndex: -1,
              debounceInterval: 500,
              filtering: false,
              sorting: true,
              pageSize: 10,
            }}
          />
        </Card>
      </GridItem>
    </GridContainer>
  );
}
