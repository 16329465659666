import React, { useContext } from "react";
import MUIDataTable from "mui-datatables";
// @material-ui/core components
// import { makeStyles } from "@material-ui/core/styles";

// material-ui icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.js";
// apis
import SubscriptionBookingService from "services/SubscriptionBookingService";
import useAPI from "utils/useAPI";
import { useHistory } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import Close from "@material-ui/icons/Close";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import Slide from "@material-ui/core/Slide";

import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import SmsIcon from "@material-ui/icons/Sms";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CollectionsBookmarkIcon from "@material-ui/icons/CollectionsBookmark";
import ManageSubscription from "@material-ui/icons/Settings";
const useStyles = makeStyles(modalStyle);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function SubscriptionBooking() {
  const api = useAPI();
  const history = useHistory();
  let path = "/user-subscription-booking/";
  const tableRef = React.useRef();
  const [modal, setModal] = React.useState(false);
  const [bookingId, setBookingId] = React.useState(0);
  const [message, setMessage] = React.useState("");
  const [data, setData] = React.useState(null);

  const classes = useStyles();

  const changeStatus = async (data) => {
    await SubscriptionBookingService.updateCurrentSubscriptionBooking(data);
  };

  const fetchSubscriptionBooking = () => {
    SubscriptionBookingService.getSubscriptionBookings().then((res) => {
      let d = transform(res);
      setData(d);
    });
  };
  React.useEffect(() => {
    SubscriptionBookingService.init(api);
    fetchSubscriptionBooking();
  }, [api]);

  const filterString = (value) => {
    return value ? value : "NA";
  };

  const formatePhone = (phoneNumberString) => {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? "+1 " : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return null;
  };

  const handleMessage = (event) => {
    // console.log(event.target.value);
    setMessage(event.target.value);
  };

  const sendSMS = () => {
    console.log("msg", bookingId, message);
    SubscriptionBookingService.sendSMS(bookingId, message).then((res) => {
      console.log("!23", res);
    });
  };

  function transform(session) {
    let transformed_subscriptions = session;
    transformed_subscriptions = transformed_subscriptions.map((ses) => {
      return {
        id: ses.id,
        name: filterString(ses.user.name),
        phone: <>{String(formatePhone(ses.user.phone)).toUpperCase() + " "}</>,
        vehicle_name: ses.vehicle_name,
        vehicle_type: ses.vehicle_type,
        // ticketUrl: ses.ticketUrl,
        vehicle_status: <>{String(ses.vehicle_status).toUpperCase() + " "}</>,
        actions: (
          <>
            <div display="flex">
              <Tooltip title="Send SMS" aria-label="">
                <IconButton
                  onClick={() => {
                    setModal(true);
                    setBookingId(ses.id);
                  }}
                >
                  <SmsIcon style={{ color: "#0C3C78" }} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Booking History" aria-label="">
                <IconButton
                  onClick={() => {
                    history.push(path + ses.id + "/history");
                  }}
                >
                  <VisibilityIcon style={{ color: "#8FD6E1" }} />
                </IconButton>
              </Tooltip>
              {ses.payment_status === "paid" &&
                ((ses.vehicle_status == "unparked" && (
                  <Tooltip title="Park" aria-label="">
                    <IconButton
                      onClick={() => {
                        changeStatus({
                          id: ses.id,
                          vehicle_status: "parked",
                        }).then(() => {
                          fetchSubscriptionBooking();
                        });
                      }}
                    >
                      <ArrowUpwardIcon color="primary" />
                    </IconButton>
                  </Tooltip>
                )) ||
                  (ses.vehicle_status == "parked" && (
                    <Tooltip title="Retrieve" aria-label="">
                      <IconButton
                        onClick={() => {
                          changeStatus({
                            id: ses.id,
                            vehicle_status: "retrieved",
                          }).then(() => {
                            fetchSubscriptionBooking();
                          });
                        }}
                      >
                        <ArrowDownwardIcon style={{ color: "red" }} />
                      </IconButton>
                    </Tooltip>
                  )))}
            </div>
          </>
        ),
      };
    });
    return transformed_subscriptions;
  }

  const columns = [
    {
      label: "ID",
      name: "id",
    },
    {
      label: "Name",
      name: "name",
    },
    {
      label: "Phone",
      name: "phone",
      options: {
        sortCompare: (order) => {
          return (obj1, obj2) => {
            let val1 = obj1.data.props.children.replace(/[^\d]/g, "");
            let val2 = obj2.data.props.children.replace(/[^\d]/g, "");
            return (val1 - val2) * (order === "asc" ? 1 : -1);
          };
        },
      },
    },
    {
      label: "Vehicle",
      name: "vehicle_name",
    },
    {
      label: "Vehicle Type",
      name: "vehicle_type",
    },
    {
      label: "Vehicle Status",
      name: "vehicle_status",
      options: {
        sortCompare: (order) => {
          return (obj1, obj2) => {
            let val1 = obj1.data.props.children;
            let val2 = obj2.data.props.children;
            return (
              (val1.length < val2.length ? -1 : 1) * (order === "asc" ? 1 : -1)
            );
          };
        },
      },
    },
    {
      label: "Actions",
      name: "actions",
    },
  ];
  const options = {
    filter: false, // Hide filters
    print: false, // Hide print
    download: false, // Hide download
    selectableRowsHeader: false, // Hide checkbox in header
    selectableRowsHideCheckboxes: true, // Hide checkbox for every row
  };
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          {data && (
            <MUIDataTable
              title={
                <div>
                  <CardIcon color="rose">
                    <CollectionsBookmarkIcon style={{ color: "white" }} />{" "}
                  </CardIcon>
                  <Typography variant="h6">SUBSCRIPTION BOOKINGS</Typography>
                </div>
              }
              data={data}
              columns={columns}
              options={options}
            />
          )}
          <Dialog
            classes={{
              root: classes.center,
              paper: classes.modal,
            }}
            open={modal}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => {
              setModal(false);
              setBookingId(0);
            }}
            aria-labelledby="modal-slide-title"
            aria-describedby="modal-slide-description"
          >
            <DialogTitle
              id="classic-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
            >
              <IconButton
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
                color="inherit"
                onClick={() => {
                  setModal(false);
                  setBookingId(0);
                }}
              >
                <Close className={classes.modalClose} />
              </IconButton>
              <h4 className={classes.modalTitle}>Send SMS</h4>
            </DialogTitle>
            <DialogContent
              id="modal-slide-description"
              className={classes.modalBody}
            >
              <TextField
                id="outlined-multiline-static"
                label="Message"
                multiline
                rows={4}
                defaultValue=""
                variant="outlined"
                fullWidth
                onChange={handleMessage}
              />
            </DialogContent>
            <DialogActions
              className={classes.modalFooter + " " + classes.modalFooterCenter}
            >
              <Button
                variant="outlined"
                onClick={() => {
                  setModal(false);
                  setMessage("");
                  setBookingId(0);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="outlined"
                onClick={() => {
                  setModal(false);
                  console.log(message, bookingId);
                  sendSMS();
                  setMessage("");
                }}
                color="primary"
              >
                Send
              </Button>
            </DialogActions>
          </Dialog>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
