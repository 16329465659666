/*eslint-disable*/
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Checkbox from "@material-ui/core/Checkbox";
import InputAdornment from "@material-ui/core/InputAdornment";
import Radio from "@material-ui/core/Radio";
import { Autocomplete } from "@material-ui/lab";
import RadioGroup from "@material-ui/core/RadioGroup";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
// core components
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import { useHistory } from "react-router-dom";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import UserService from "services/UserService";
import VehicleService from "services/VehicleService";
import FeeStructureService from "services/FeeStructureService";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import LocationService from "services/LocationService";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import useAPI from "utils/useAPI";
const useStyles = makeStyles(styles);

function FeeForm({ id = null }) {
  var numberRex = new RegExp("^[0-9]+$");
  const [isEdit] = React.useState(id ? true : false);
  const [locationList, setLocationList] = React.useState([]);
  const [FeeLabel] = React.useState(
    id ? "Update Fee Structure" : "Add Fee Structure"
  );
  const type = [{ name: "Hour" }, { name: "Minutes" }];

  const [fee, setFee] = React.useState({
    amount: "",
    duration: "",
    type: "Hour",
    to_all_locations: true,
    location: [],
  });
  const api = useAPI();
  const history = useHistory();

  const [errors, setErrors] = React.useState({
    amount: "",
    duration: "",
    type: "",
  });

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const fetchLocationList = async () => {
    await LocationService.getLocations().then((res) => {
      setLocationList(
        res.map((item) => {
          return {
            id: item.id,
            name: item.name + " - " + item.zipcode,
            value: item.id,
          };
        })
      );
      if (res.length == 1) {
        const defaultLocation = [res[0].id];
        setFee({ ...fee, location: defaultLocation });
      }
    });
  };

  const fetchFeeStructure = async () => {
    await FeeStructureService.getFees(id).then((res) => {
      console.log("RES", res);
      let locations = [];
      res.location.map((item) => {
        locations.push(item.id);
      });
      res.location = locations;
      setFee(res);
    });
  };

  React.useEffect(() => {
    FeeStructureService.init(api);
    LocationService.init(api);
    fetchLocationList();
    if (id) fetchFeeStructure();
  }, [api]);

  const handleChange = (prop, val) => {
    setFee({ ...fee, [prop]: val });
    if (verifyLength(val, 1)) {
      setErrors({ ...errors, [prop]: "success" });
    } else {
      setErrors({ ...errors, [prop]: "error" });
    }
  };

  const verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };

  const verifyNumber = (value) => {
    if (numberRex.test(value) && value.length >= 1) {
      return true;
    }
    return false;
  };

  const registerClick = async () => {
    let error = 0;
    let newErrors = errors;
    ["amount", "duration", "location"].forEach((field) => {
      if (field == "location") {
        if (!fee.to_all_locations && !fee["location"].length) {
          console.log(fee.to_all_locations, fee["location"].length);
          newErrors["location"] = "error";
          error = 1;
        }
      } else if (fee[field] === "" || !numberRex.test(fee[field])) {
        newErrors[field] = "error";
        error = 1;
      }
    });
    console.log("Error", newErrors);
    setErrors({ ...newErrors });

    if (error) return;

    if (id) await FeeStructureService.updateFee(fee);
    else await FeeStructureService.storeFee(fee);
    history.push("/admin/fee-structure");
  };
  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={6}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <MonetizationOnIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>{FeeLabel}</h4>
          </CardHeader>
          <CardBody>
            <form>
              <TextField
                error={errors.amount === "error"}
                margin="dense"
                id="amount"
                label="Amount *"
                variant="outlined"
                type="text"
                fullWidth
                value={fee.amount}
                onChange={(event) => {
                  if (verifyNumber(event.target.value)) {
                    setErrors({ ...errors, amount: "success" });
                  } else {
                    setErrors({ ...errors, amount: "error" });
                  }
                  setFee({ ...fee, amount: event.target.value });
                }}
              />

              <TextField
                error={errors.duration === "error"}
                margin="dense"
                id="duration"
                label="Duration *"
                variant="outlined"
                type="text"
                fullWidth
                value={fee.duration}
                onChange={(event) => {
                  if (verifyNumber(event.target.value)) {
                    setErrors({ ...errors, amount: "success" });
                  } else {
                    setErrors({ ...errors, amount: "error" });
                  }
                  setFee({ ...fee, duration: event.target.value });
                }}
              />

              <FormControl
                className={classes.selectFormControl}
                variant="outlined"
                margin="dense"
                fullWidth
              >
                <InputLabel>Choose Type *</InputLabel>
                <Select
                  value={fee.type}
                  onChange={(e) => {
                    handleChange("type", e.target.value);
                  }}
                  error={errors.type === "error"}
                  label="Choose Type *"
                >
                  <MenuItem
                    disabled
                    value=""
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    Choose Type *
                  </MenuItem>

                  {type.map((type) => (
                    <MenuItem
                      value={`${type.name}`}
                      key={`${type.name}`}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                    >
                      {`${type.name}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControlLabel
                control={
                  <Checkbox
                    checked={fee.to_all_locations}
                    onChange={() => {
                      console.log("Changed", event.target.checked);
                      setFee({
                        ...fee,
                        to_all_locations: event.target.checked,
                      });
                    }}
                  />
                }
                label="Applies to All Locations"
              />

              {!fee.to_all_locations && (
                <FormControl
                  fullWidth
                  variant="outlined"
                  className={classes.selectFormControl}
                >
                  <InputLabel
                    htmlFor="multiple-select"
                    className={classes.selectLabel}
                  >
                    Choose Specific Location to Apply Fee Structure
                  </InputLabel>
                  <Select
                    multiple
                    value={fee.location}
                    error={errors.location === "error"}
                    onChange={(e) => {
                      setFee({ ...fee, location: e.target.value });
                      if (verifyLength(e.target.value, 1)) {
                        setErrors({ ...errors, location: "success" });
                      } else {
                        setErrors({ ...errors, location: "error" });
                      }
                    }}
                    MenuProps={{ className: classes.selectMenu }}
                    classes={{ select: classes.select }}
                    inputProps={{
                      name: "multipleSelect",
                      id: "multiple-select",
                    }}
                    label="Choose Specific Location to Apply Fee Structure"
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                    >
                      Choose Location
                    </MenuItem>
                    {locationList &&
                      locationList.map((location) => (
                        <MenuItem
                          value={`${location.id}`}
                          key={`${location.id}`}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                        >
                          {`${location.name}`}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}

              <div className={classes.formCategory}>
                <small>*</small> Required fields
              </div>

              <Button
                color="rose"
                onClick={registerClick}
                className={classes.registerButton}
              >
                {FeeLabel}
              </Button>
            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

FeeForm.propTypes = {
  id: PropTypes.string,
};

FeeForm.defaultProps = {
  id: null,
};

export default FeeForm;
