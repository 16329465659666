import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// material-ui icons
import GroupIcon from "@material-ui/icons/Group";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import TransactionService from "services/TransactionService";
import useAPI from "utils/useAPI";
import MaterialTable, { MTableToolbar } from "material-table";
import moment from "moment";
import ReceiptIcon from "@material-ui/icons/Receipt";

const useStyles = makeStyles((theme) => ({
  ...styles,
  chip: {
    margin: theme.spacing(0.5),
  },
}));

export default function Transactions() {
  const api = useAPI();
  const classes = useStyles();

  const getDate = (dateTime) => {
    // return new Date(dateTime);
    return moment(dateTime).format("L");
  };

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const viewInvoice = (transaction) => {
    TransactionService.getInvoiceLink(transaction).then((result) => {
      openInNewTab(result);
    });
  };
  function transformTransaction(transaction) {
    let transformed_transaction = transaction;
    transformed_transaction = transformed_transaction.map((transaction) => {
      return {
        id: transaction.id,
        stripe_payment_intent_id: transaction.stripe_payment_intent_id,
        "parkingSession.user_id": transaction.parkingSession.user.name,
        "parkingSession.discount_id": transaction.parkingSession.discount
          ? transaction.parkingSession.discount.type === "Percentage"
            ? transaction.parkingSession.discount.value + "%"
            : transaction.parkingSession.discount.value + "$"
          : "NA",
        amount: `${transaction.price}`,
        duration: transaction.parkingSession.duration,
        pin: transaction.parkingSession.user_vehicle_pin,
        date: getDate(transaction.parkingSession.start_time),
        vehicle: transaction.parkingSession.vehicle_name,
        status: String(transaction.status).toUpperCase(),
      };
    });
    return transformed_transaction;
  }

  React.useEffect(() => {
    TransactionService.init(api);
  }, [api]);

  return (
    <GridContainer>
      <div className={classes.cardContentLeft}></div>
      <GridItem xs={12}>
        <Card>
          <MaterialTable
            title="Transactions"
            columns={[
              { title: "Name", field: "parkingSession.user_id" },
              { title: "Amount ($)", field: "amount" },
              { title: "Duration", field: "duration" },
              { title: "Date", field: "date", sorting: false },
              { title: "Vehicle", field: "vehicle", sorting: false },
              { title: "Pin", field: "pin" },
              { title: "Status", field: "status" },
              { title: "Discount", field: "parkingSession.discount_id" },
            ]}
            data={(query) =>
              new Promise((resolve, reject) => {
                TransactionService.getTransactions(
                  query.pageSize,
                  query.page + 1,
                  query.search,
                  query.orderBy ? query.orderBy.field : null,
                  query.orderDirection
                )
                  .then((res) => {
                    resolve({
                      data: transformTransaction(res.results),
                      page: res.page - 1,
                      totalCount: res.total,
                    });
                  })
                  .catch(reject);
              })
            }
            actions={[
              (rowData) => ({
                icon: () => <ReceiptIcon />,
                iconProps: { fontSize: "small" },
                tooltip: "View Invoice",
                onClick: () => {
                  viewInvoice(rowData.stripe_payment_intent_id);
                },
              }),
            ]}
            components={{
              Toolbar: (props) => (
                <div>
                  <CardHeader color="primary" icon>
                    <CardIcon color="rose">
                      <GroupIcon />
                    </CardIcon>
                  </CardHeader>
                  <MTableToolbar {...props} />
                </div>
              ),
            }}
            options={{
              actionsColumnIndex: -1,
              debounceInterval: 500,
              filtering: false,
              sorting: true,
              pageSize: 10,
            }}
          />
        </Card>
      </GridItem>
    </GridContainer>
  );
}
