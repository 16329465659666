import React from "react";
// @material-ui/core components
// import { makeStyles } from "@material-ui/core/styles";

// material-ui icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import { makeStyles } from "@material-ui/core/styles";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.js";
import MenuItem from "@material-ui/core/MenuItem";
// apis
import TicketService from "services/TicketService";
import useAPI from "utils/useAPI";
import { useHistory } from "react-router-dom";
import MaterialTable, { MTableToolbar } from "material-table";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import Close from "@material-ui/icons/Close";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import ListAltIcon from "@material-ui/icons/ListAlt";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
const useStyles = makeStyles(modalStyle);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const vehicleStatusList = [
  { id: 0, name: "unparked" },
  { id: 1, name: "parked" },
  { id: 2, name: "retrieved" },
];

export default function Tickets() {
  const api = useAPI();
  const history = useHistory();
  let path = "/ticket/edit/";
  const tableRef = React.useRef();
  const [modal, setModal] = React.useState(false);
  const [vehicleStatus, setVehicleStatus] = React.useState("");
  const [ticketId, setTicketId] = React.useState(0);
  const classes = useStyles();

  const handleChange = (value) => {
    setVehicleStatus(value);
  };

  const changeStatus = async (data) => {
    await TicketService.updateTicket(data);
  };

  React.useEffect(() => {
    TicketService.init(api);
  }, [api]);

  const filterString = (value) => {
    return value ? value : "-";
  };

  function transform(session) {
    let transformed_ticket = session;
    transformed_ticket = transformed_ticket.map((ses) => {
      return {
        id: ses.id,
        name: filterString(ses.name),
        phone: ses.phone,
        vehicle_type: ses.vehicle_type,
        plate: filterString(ses.plate),
        color: filterString(ses.color),
        vehicle_exit: filterString(ses.vehicle_exit),
        vehicle_damage: filterString(ses.vehicle_damage),
        payment_status: (
          <>
            {" "}
            <div display="flex">
              <FormControlLabel
                control={
                  <Switch
                    checked={ses.payment_status == "paid" ? true : false}
                    // checked={true}
                    onChange={(e) => {
                      changeStatus({
                        id: ses.id,
                        payment_status: e.target.checked ? "paid" : "unpaid",
                      }).then(() => {
                        tableRef.current && tableRef.current.onQueryChange();
                      });
                    }}
                    name="payment_status"
                  />
                  // String(filterString(ses.payment_status)).toUpperCase()
                }
                label={
                  ses.payment_status
                    ? String(capitalizeFirstLetter(ses.payment_status))
                    : "NA"
                }
              />
            </div>
          </>
        ),
        payment_type: String(filterString(ses.payment_type)).toUpperCase(),
        ticketUrl: ses.ticketUrl,
        vehicle_status: (
          <>
            {String(ses.vehicle_status).toUpperCase() + " "}
            <div display="flex">
              {ses.vehicle_status == "unparked" && (
                <Tooltip title="Park" aria-label="">
                  <IconButton
                    onClick={() => {
                      // setModal(false);
                      setVehicleStatus("parked");
                      setTicketId(ses.id);
                      changeStatus({
                        id: ses.id,
                        vehicle_status: "parked",
                      }).then(() => {
                        tableRef.current && tableRef.current.onQueryChange();
                      });
                      setVehicleStatus("");
                      setTicketId(0);
                    }}
                  >
                    <ArrowUpwardIcon />
                  </IconButton>
                </Tooltip>
              )}
              {ses.vehicle_status == "parked" && (
                <Tooltip title="Retrieve" aria-label="">
                  <IconButton
                    onClick={() => {
                      // setModal(false);
                      setVehicleStatus("retrieved");
                      setTicketId(ses.id);
                      changeStatus({
                        id: ses.id,
                        vehicle_status: "retrieved",
                      }).then(() => {
                        tableRef.current && tableRef.current.onQueryChange();
                      });
                      setVehicleStatus("");
                      setTicketId(0);
                    }}
                  >
                    <ArrowDownwardIcon />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          </>
        ),
      };
    });
    return transformed_ticket;
  }
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <MaterialTable
            title="Tickets"
            tableRef={tableRef}
            columns={[
              { title: "Name", field: "name" },
              { title: "Phone", field: "phone" },
              { title: "Vehicle Type", field: "vehicle_type" },
              { title: "Plate", field: "plate" },
              { title: "Color", field: "color" },
              // { title: "Exit", field: "vehicle_exit" },
              // { title: "Vehicle Damage", field: "vehicle_damage" },
              {
                title: "Vehicle Status",
                field: "vehicle_status",
                cellStyle: { fontSize: 12 },
              },
              { title: "Payment Status", field: "payment_status" },
              { title: "Payment Type", field: "payment_type" },
            ]}
            data={(query) =>
              new Promise((resolve, reject) => {
                TicketService.getTickets(
                  query.pageSize,
                  query.page + 1,
                  query.search,
                  query.orderBy ? query.orderBy.field : null,
                  query.orderDirection
                )
                  .then((res) => {
                    resolve({
                      data: transform(res.results),
                      page: res.page - 1,
                      totalCount: res.total,
                    });
                  })
                  .catch(reject);
              })
            }
            actions={[
              {
                icon: "add",
                tooltip: "Add Ticket",
                position: "toolbar",
                isFreeAction: true,
                onClick: () => {
                  history.push("/ticket/create");
                },
              },

              (rowData) => ({
                icon: "edit",
                iconProps: { fontSize: "small" },
                tooltip: "Update Ticket",
                onClick: () => {
                  history.push(path + rowData.id);
                },
              }),
              (rowData) => ({
                icon: "link",
                iconProps: { fontSize: "small" },
                tooltip: "Open Ticket",
                onClick: () => {
                  window.open(rowData.ticketUrl);
                },
              }),
              (rowData) => ({
                icon: "sms",
                iconProps: { fontSize: "small" },
                tooltip: "Resend SMS",
                onClick: () => {
                  TicketService.resendSMS(rowData.id).catch(console.log);
                },
              }),
            ]}
            components={{
              Toolbar: (props) => (
                <div style={{ alignItems: "center", paddingRight: "0%" }}>
                  <CardHeader color="primary" icon>
                    <CardIcon color="rose">
                      <ListAltIcon />
                    </CardIcon>
                  </CardHeader>
                  <MTableToolbar {...props} />
                </div>
              ),
            }}
            options={{
              actionsColumnIndex: -1,
              debounceInterval: 500,
              pageSize: 10,
            }}
          />
          <Dialog
            classes={{
              root: classes.center,
              paper: classes.modal,
            }}
            open={modal}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => {
              setModal(false);
              setVehicleStatus("");
              setTicketId(0);
            }}
            aria-labelledby="modal-slide-title"
            aria-describedby="modal-slide-description"
          >
            <DialogTitle
              id="classic-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
            >
              <IconButton
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
                color="inherit"
                onClick={() => {
                  setModal(false);
                  setVehicleStatus("");
                  setTicketId(0);
                }}
              >
                <Close className={classes.modalClose} />
              </IconButton>
              <h4 className={classes.modalTitle}>Change Vehicle Status</h4>
            </DialogTitle>
            <DialogContent
              id="modal-slide-description"
              className={classes.modalBody}
            >
              {/* <h5>Are you sure you want to do this?</h5> */}
              <Select
                fullWidth
                id="vehicle_status"
                value={vehicleStatus}
                required
                onChange={(event) => {
                  handleChange(event.target.value);
                }}
                // style={("paddingTop" = "10px")}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  Vehicle Status *
                </MenuItem>

                {vehicleStatusList &&
                  vehicleStatusList.map((item) => (
                    <MenuItem
                      value={`${item.name}`}
                      key={`${item.id}`}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                    >
                      {capitalizeFirstLetter(`${item.name}`)}
                    </MenuItem>
                  ))}
              </Select>
            </DialogContent>
            <DialogActions
              className={classes.modalFooter + " " + classes.modalFooterCenter}
            >
              <Button
                onClick={() => {
                  setModal(false);
                  setVehicleStatus("");
                  setTicketId(0);
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  setModal(false);
                  console.log(vehicleStatus);
                  changeStatus({
                    id: ticketId,
                    vehicle_status: vehicleStatus,
                  }).then(() => {
                    console.log("Completed");
                    tableRef.current && tableRef.current.onQueryChange();
                  });
                  setVehicleStatus("");
                  setTicketId(0);
                }}
                color="primary"
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
