import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import CreateIcon from "@material-ui/icons/Create";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import InputMask from "react-input-mask";
import { useHistory } from "react-router-dom";
import useAPI from "utils/useAPI";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import TicketService from "services/TicketService";
import { FormControl } from "@material-ui/core";

const useStyles = makeStyles(styles);

const vehicleSizeList = [
  { id: 1, name: "Non Electric SUV" },
  { id: 2, name: "Electric SUV" },
  { id: 3, name: "Non Electric Car" },
  { id: 4, name: "Electric Car" },
];
export default function RegularForms() {
  const classes = useStyles();
  const history = useHistory();
  const api = useAPI();
  const color = [
    { name: "Gold" },
    { name: "Brown" },
    { name: "Green" },
    { name: "Yellow" },
    { name: "Orange" },
    { name: "Gray" },
    { name: "Silver" },
    { name: "Space Gray" },
    { name: "Blue" },
    { name: "Red" },
    { name: "Black" },
    { name: "White" },
  ];

  const [ticket, setTicket] = React.useState({
    phone: "",
    name: "",
    vehicleType: "",
    color: "",
    plate: "",
  });
  const [errors, setErrors] = React.useState({
    phone: "",
    name: "",
    vehicleType: "",
    color: "",
    plate: "",
  });
  React.useEffect(() => {
    TicketService.init(api);
  }, [api]);

  const handleChange = (prop) => (event) => {
    console.log("Changed", prop);
    setTicket({ ...ticket, [prop]: event.target.value });
    if (verifyLength(event.target.value, 1)) {
      setErrors({ ...errors, [prop]: "success" });
    } else {
      setErrors({ ...errors, [prop]: "error" });
    }
  };
  const verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };

  const handleSave = async () => {
    let error = 0;
    let newErrors = errors;
    ["phone", "vehicleType", "color", "plate"].forEach((field) => {
      if (ticket[field] === "") {
        newErrors[field] = "error";
        error = 1;
      }
    });
    setErrors({ ...newErrors });

    if (error) return;
    let path = `/admin/ticket`;
    await TicketService.storeTicket(ticket).then(() => {
      history.push(path);
    });
  };

  const cancelRoute = () => {
    window.history.back();
    // let path = `/admin/carousels`;
    // history.push(path);
  };
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={6}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <CreateIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Add Ticket</h4>
          </CardHeader>
          <CardBody>
            <form>
              <InputMask
                mask="+1 (999) 999 9999"
                // value={values.phone}
                disabled={false}
                onChange={handleChange("phone")}
                maskChar=" "
              >
                {() => (
                  <TextField
                    error={errors.phone === "error"}
                    autoFocus
                    margin="dense"
                    id="phone"
                    label="Phone *"
                    variant="outlined"
                    // value={ticket.phone}
                    type="text"
                    fullWidth
                  />
                )}
              </InputMask>

              <TextField
                error={errors.name === "error"}
                autoFocus
                margin="dense"
                id="name"
                label="Name"
                variant="outlined"
                type="text"
                fullWidth
                onChange={handleChange("name")}
              />

              <FormControl
                variant="outlined"
                fullWidth
                margin="dense"
                className={classes.formControl}
              >
                <InputLabel id="vehicle_type">Vehicle Type *</InputLabel>
                <Select
                  labelId="vehicle_type"
                  id="vehicle_type"
                  fullWidth
                  value={ticket.vehicleType}
                  onChange={handleChange("vehicleType")}
                  label="Vehicle Type *"
                >
                  <MenuItem
                    disabled
                    value=""
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    Choose Vehicle Type
                  </MenuItem>

                  {vehicleSizeList &&
                    vehicleSizeList.map((item) => (
                      <MenuItem
                        value={`${item.name}`}
                        key={`${item.id}`}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                      >
                        {`${item.name}`}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>

              <TextField
                error={errors.plate === "error"}
                autoFocus
                margin="dense"
                id="plate"
                label="Plate *"
                variant="outlined"
                type="text"
                fullWidth
                onChange={handleChange("plate")}
              />

              <FormControl
                variant="outlined"
                fullWidth
                margin="dense"
                className={classes.formControl}
              >
                <InputLabel id="color">Color *</InputLabel>
                <Select
                  labelId="color"
                  id="color"
                  fullWidth
                  value={ticket.color}
                  onChange={handleChange("color")}
                  label="Color *"
                >
                  <MenuItem
                    disabled
                    value=""
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    Choose Color *
                  </MenuItem>

                  {color.map((color) => (
                    <MenuItem
                      value={`${color.name}`}
                      key={`${color.name}`}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                    >
                      {`${color.name}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <Button color="success" onClick={handleSave}>
                Submit
              </Button>
              <Button color="danger" onClick={cancelRoute}>
                Cancel
              </Button>
            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
