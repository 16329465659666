import React from "react";
import MUIDataTable from "mui-datatables";

// @material-ui/core components
// import { makeStyles } from "@material-ui/core/styles";

// material-ui icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import { makeStyles } from "@material-ui/core/styles";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.js";
// apis
import SubscriptionBookingService from "services/SubscriptionBookingService";
import useAPI from "utils/useAPI";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import Slide from "@material-ui/core/Slide";

import CollectionsBookmarkIcon from "@material-ui/icons/CollectionsBookmark";
import ReceiptIcon from "@material-ui/icons/Receipt";

import moment from "moment";

const useStyles = makeStyles(modalStyle);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function SubscriptionBooking() {
  const api = useAPI();
  let path = "/user-subscription-booking/";
  const [data, setData] = React.useState(null);

  const classes = useStyles();

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const viewInvoice = (stripeSubscriptionId) => {
    SubscriptionBookingService.getInvoiceLink(stripeSubscriptionId).then(
      (result) => {
        openInNewTab(result);
      }
    );
  };

  const fetchSubscriptionBooking = () => {
    SubscriptionBookingService.getSubscriptionBookings().then((res) => {
      const d = transform(res);
      setData(d);
    });
  };
  React.useEffect(() => {
    SubscriptionBookingService.init(api);
    fetchSubscriptionBooking();
  }, [api]);

  const filterString = (value) => {
    return value ? value : "NA";
  };

  const formatePhone = (phoneNumberString) => {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? "+1 " : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return null;
  };

  function transform(session) {
    console.log("session", session);
    let transformed_subscriptions = session;
    transformed_subscriptions = transformed_subscriptions.map((ses) => {
      return {
        id: ses.id,
        name: filterString(ses.user.name),
        phone: String(formatePhone(ses.user.phone)).toUpperCase() + " ",
        vehicle_name: ses.vehicle_name,
        plan_name: ses.plan ? ses.plan.plan_name : "NA",
        start_date: ses.plan
          ? moment(ses.start_date).format("MM/DD/YYYY hh:mm A")
          : "NA",
        end_date: ses.plan
          ? moment(ses.end_date).format("MM/DD/YYYY hh:mm A")
          : "NA",
        next_billing_date: ses.plan
          ? moment(ses.next_billing_date).format("MM/DD/YYYY hh:mm A")
          : "NA",
        // ticketUrl: ses.ticketUrl,
        actions: (
          <>
            <Tooltip title="View Invoice" aria-label="">
              <IconButton
                onClick={() => {
                  viewInvoice(ses.stripe_subscription_id);
                }}
              >
                <ReceiptIcon />
              </IconButton>
            </Tooltip>
          </>
        ),
      };
    });
    return transformed_subscriptions;
  }
  const options = {
    filter: false, // Hide filters
    print: false, // Hide print
    download: false, // Hide download
    selectableRowsHeader: false, // Hide checkbox in header
    selectableRowsHideCheckboxes: true, // Hide checkbox for every row
  };
  let columns = [
    // { label: "ID", key: "id" },
    { label: "Name", name: "name" },
    { label: "Phone", name: "phone" },
    { label: "Vehicle", name: "vehicle_name" },
    { label: "Plan", name: "plan_name" },
    { label: "Start Date", name: "start_date" },
    { label: "End Date", name: "end_date" },
    { label: "Next Billing Date", name: "next_billing_date" },
    { label: "Actions", name: "actions" },
  ];

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          {data && (
            <MUIDataTable
              title={
                <div>
                  <CardIcon color="rose">
                    <CollectionsBookmarkIcon style={{ color: "white" }} />{" "}
                  </CardIcon>
                  <Typography variant="h6">
                    SUBSCRIPTION TRANSACTIONS
                  </Typography>
                </div>
              }
              data={data}
              columns={columns}
              options={options}
            />
          )}
        </Card>
      </GridItem>
    </GridContainer>
  );
}
