import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import FeeStructureService from "services/FeeStructureService";
import useAPI from "utils/useAPI";
import buttons from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.js";
import { useHistory } from "react-router-dom";
import tableStyles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import MaterialTable, { MTableToolbar } from "material-table";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
    buttons,
  },
  ...tableStyles,
};

const useStyles = makeStyles(styles);

export default function ReactTables() {
  const api = useAPI();
  const history = useHistory();
  let path = "/fee-structure/edit/";
  const tableRef = React.createRef();

  React.useEffect(() => {
    FeeStructureService.init(api);
  }, [api]);

  function transformFee(Fee) {
    let transformed_fees = Fee;
    transformed_fees = transformed_fees.map((fee) => {
      return {
        id: fee.id,
        amount: fee.amount,
        duration: fee.duration + " " + fee.type,
      };
    });
    return transformed_fees;
  }

  const classes = useStyles();
  return (
    <GridContainer>
      <div className={classes.cardContentLeft}></div>
      <GridItem xs={12}>
        <Card>
          <MaterialTable
            title="Fee Structure"
            tableRef={tableRef}
            columns={[
              { title: "#", field: "id" },
              { title: "Amount($)", field: "amount" },
              { title: "Duration", field: "duration", sorting: false },
            ]}
            data={(query) =>
              new Promise((resolve, reject) => {
                FeeStructureService.getFeeStructure(
                  query.pageSize,
                  query.page + 1,
                  query.search,
                  query.orderBy ? query.orderBy.field : null,
                  query.orderDirection
                )
                  .then((res) => {
                    resolve({
                      data: transformFee(res.results),
                      page: res.page - 1,
                      totalCount: res.total,
                    });
                  })
                  .catch(reject);
              })
            }
            actions={[
              {
                icon: "add",
                tooltip: "Add FeeStructure",
                position: "toolbar",
                onClick: () => {
                  history.push("/fee-structure/add");
                },
              },
              (rowData) => ({
                icon: "edit",
                iconProps: { fontSize: "small" },
                tooltip: "Update FeeStructure",
                onClick: () => {
                  history.push(path + rowData.id);
                },
              }),
              (rowData) => ({
                icon: "delete",
                iconProps: { fontSize: "small", style: { color: "red" } },
                tooltip: "Delete",
                onClick: async () => {
                  await FeeStructureService.deleteFee(rowData.id);
                  tableRef.current && tableRef.current.onQueryChange();
                },
              }),
            ]}
            components={{
              Toolbar: (props) => (
                <div style={{ alignItems: "center", paddingRight: "0%" }}>
                  <CardHeader color="primary" icon>
                    <CardIcon color="rose">
                      <MonetizationOnIcon />
                    </CardIcon>
                  </CardHeader>
                  <MTableToolbar {...props} />
                </div>
              ),
            }}
            options={{
              actionsColumnIndex: -1,
              debounceInterval: 500,
              sorting: true,
              pageSize: 10,
            }}
          />
        </Card>
      </GridItem>
    </GridContainer>
  );
}
